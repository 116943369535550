import {
  BaseLayout,
  IPageLayoutProps,
  Typography,
  UserMenuButton,
  WalletUser,
} from '@collection-platform-frontend/ui';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { AuthUserContext } from 'next-firebase-auth';
import { FC, useCallback } from 'react';

import { Icons } from './icons';

export type HeaderProps = {
  authUser?: AuthUserContext;
  user?: WalletUser;
};

const menus = [
  { label: 'コレクション', href: '/collection' },
  { label: '設定', href: '/setting' },
];

export const Header: FC<HeaderProps> = ({ user, authUser }) => {
  const { push, asPath } = useRouter();

  const onLink = useCallback(
    (link: string) => {
      push(link);
    },
    [push],
  );

  const onLogin = useCallback(() => {
    const url = new URL('/auth', window.location.origin);
    url.searchParams.set('callbackUrl', asPath);

    push(url.toString());
  }, [push, asPath]);

  const onLogout = useCallback(() => {
    authUser?.signOut();
  }, [authUser]);

  return (
    <div className="flex items-center justify-center dark:text-wallet-primary text-wallet-light-primary dark:bg-wallet-base bg-wallet-light-base">
      <div className="flex items-center justify-between w-full max-w-6xl px-4 py-4">
        <Link href="/">
          <Icons.WalletLogo className="cursor-pointer h-[40px]" />
        </Link>
        {authUser ? (
          <UserMenuButton
            user={user}
            menus={menus}
            onLink={onLink}
            onLogin={onLogin}
            onLogout={onLogout}
          />
        ) : (
          <div className="h-[40px]" />
        )}
      </div>
    </div>
  );
};

export const Footer: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center dark:text-wallet-primary text-wallet-light-primary dark:bg-wallet-base bg-wallet-light-base">
      <div className="flex items-center justify-end w-full max-w-6xl px-2">
        <a
          href="https://support.anique.jp/hc/ja/requests/new"
          target="_blank"
          rel="noreferrer"
        >
          <div className="px-2 py-4 cursor-pointer hover:underline">
            <Typography variant="body2">お問い合わせ</Typography>
          </div>
        </a>
        <Link href="/terms">
          <div className="px-2 py-4 cursor-pointer hover:underline">
            <Typography variant="body2">利用規約</Typography>
          </div>
        </Link>
        <Link href="/policy">
          <div className="px-2 py-4 cursor-pointer hover:underline">
            <Typography variant="body2">プライバシー</Typography>
          </div>
        </Link>
      </div>
    </div>
  );
};

export const PageLayout: FC<IPageLayoutProps> = ({ children, ...rest }) => {
  return (
    <>
      <BaseLayout
        className="dark:text-wallet-primary text-wallet-light-primary dark:bg-wallet-base bg-wallet-light-base"
        headerContent={<Header {...rest} />}
        mainContent={
          <div className="relative flex flex-col items-center w-full">
            <div className="flex items-center justify-between w-full max-w-6xl">
              {children}
            </div>
          </div>
        }
        footerContent={<Footer />}
      />
    </>
  );
};
